.select-players {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F4F7F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .select-players h3 {
    font-size: 20px;
    text-align: center; }

.wheel-wrap {
  text-align: center;
  padding-top: 100px; }
  .wheel-wrap h1 {
    margin-block-end: 60px; }
  .wheel-wrap button, .wheel-wrap input {
    border: 0;
    outline: none;
    border-radius: 30px;
    background-color: #70CFA6;
    color: #FFFFFF;
    font-size: 18px;
    padding: 12px 45px; }

.table {
  padding-top: 40px;
  text-align: center; }
  .table table {
    text-align: left;
    margin-bottom: 32px;
    width: 100%; }
    .table table tr th {
      background-color: #70CFA6;
      color: #FFFFFF; }
  .table button, .table input {
    border: 0;
    outline: none;
    border-radius: 30px;
    background-color: #70CFA6;
    color: #FFFFFF;
    font-size: 18px;
    padding: 12px 45px;
    margin: 0 auto; }

.select-color {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F4F7F6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .select-color .colors {
    width: 100%;
    float: left; }
    .select-color .colors .color {
      width: 20%;
      height: 70px;
      margin: 0 10px;
      float: left;
      margin-bottom: 15px;
      background-color: #70CFA6;
      cursor: pointer; }

.colors-box {
  width: 80%;
  transform-style: preserve-3d;
  perspective: 600px; }
  .colors-box .box {
    float: left;
    width: 50%;
    height: 50px;
    transform: rotateX(50deg) translateY(160px); }

.color-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.players {
  width: 100%;
  text-align: center;
  margin: 40px 0; }
  .players button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: blue;
    border: 0;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    margin: 10px; }

.select-players h1 {
  text-align: center; }

.select-players h2 {
  text-align: center; }

html, body {
  min-height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  background-color: #F4F7F6; }

html, body, h1, h2, h3, h4, h5, h6, p, a, span, div {
  font-family: 'Poppins', sans-serif; }

button {
  cursor: pointer; }

.select-field {
  border: 1px solid #cacaca;
  min-width: 100%;
  padding: 0;
  height: 3rem;
  font-size: 16px; }
  .select-field a {
    color: #041926; }
  .select-field .dropdown-menu {
    min-width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff; }
  .select-field .dropdown-toggle {
    height: 3rem;
    float: left;
    width: 100%;
    padding: 16px;
    background-color: #FFFFFF; }
    .select-field .dropdown-toggle::after {
      display: none; }
    .select-field .dropdown-toggle::before {
      content: " ";
      display: block;
      border-top: 2px solid #041926;
      border-right: 2px solid #041926;
      width: 10px;
      height: 10px;
      transform: rotate(135deg);
      float: right;
      margin-top: 4px; }
